<template>
  <General>
    <FormDesigner class="form-designer" ref="formDesigner"></FormDesigner>
  </General>
</template>

<script>
import { getLanguage } from "@/util";
import General from "@/layouts/general";

export default {
  components: { General },
  beforeCreate() {
    this.$options.components.FormDesigner = window.formDesigner.FormDesigner;
  },
  mounted() {
    this.$refs.formDesigner.setLanguage(getLanguage());
  },
};
</script>

<style lang="scss" scoped>
.form-designer /deep/ {
  .row {
    margin: 0;
  }
  .container {
    padding: 0;
  }
}
</style>
